import React from 'react'

import { Base404Page } from '@product-site-frontend/shared';

import PageBox from '../components/PageBox';
import SEO from '../components/SEO';

export default function NotFoundPage() {
  return (
    <PageBox>
      <SEO pageData={{ title: 'Страница не найдена' }} />
      <Base404Page />
    </PageBox>
  )
}